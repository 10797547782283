<template>
  <v-card
    v-if="run"
    :ripple="!readOnly"
    :class="{
      'rip-off':readOnly,
      'light-variant': light,
      'sold-out-card': run.collectedAmount === 0,
      'wasted-card': wasted,
      'saved-food-block': run.collectedAmount && $route.name !== 'runs.view',
      'run-card': run.collectedAmount === null || $route.name === 'runs.view'
    }"
    :to="link ? {name: 'runs.view', params: {id: run.id}} : null"
  >
    <template v-if="run.collectedAmount && $route.name !== 'runs.view'">
      <div class="card-content">
        <div class="content-main">
          <h5 class="card-title">{{ donorName }}</h5>
          <div v-if="run.claimed && $store.getters.isAdmin" class="card-status">
            <span>Claimed&nbsp;by:&nbsp;
              <a :href="'tel:'+run.volunteerPhone">
                <strong> {{ run.volunteerName }} </strong>
              </a>
            </span>
            <div>Shop&nbsp;no:&nbsp;
              <a :href="'tel:'+run.donor.phone">
                <strong>{{ run.donor.phone }}</strong>
              </a>
            </div>
          </div>
        </div>
        <div class="content-info">
          <div class="card-info">{{ run.collectedAmount }}</div>
          <div class="avail-mark">
            <img src="/images/bun-saved.svg">
            <span>Saved</span>
          </div>
          <v-icon>mdi-checkbox-marked-circle</v-icon>
        </div>
      </div>
    </template>
    <template v-else-if="run.collectedAmount === 0">
      <div class="card-content sold-out">
        <div class="content-main">
          <h5 class="card-title">{{ donorName }}</h5>
          <div v-if="run.claimed && $store.getters.isAdmin" class="card-status">
            <span>Claimed&nbsp;by:&nbsp;
              <a :href="'tel:'+run.volunteerPhone">
                <strong> {{ run.volunteerName }} </strong>
              </a>
            </span>
            <div>Shop&nbsp;no:&nbsp;
              <a :href="'tel:'+run.donor.phone">
                <strong>{{ run.donor.phone }}</strong>
              </a>
            </div>
          </div>
        </div>
        <div class="content-info">
          <span>{{ $t("runcard.soldout")}}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="card-content">
        <v-btn v-if="removable" @click="$emit('remove', run.id)" class="remove" fab dark small>
          <v-icon dark>mdi-window-close</v-icon>
        </v-btn>
        <template v-if="!($store.getters.isVolunteer || $store.getters.isLeader)">
          <div class="status-label" :class="run.claimed ? 'claimed' : 'unclaimed'">
            {{ run.claimed ? 'claimed' : 'unclaimed' }}
          </div>
        </template>
        <div class="content-main">
          <h5 class="card-title">{{ donorName }}</h5>
          <address>
            <ul class="address-list">
              <li>
                <img src="/images/map-marker.svg">
                <span>{{ donorAddress }}</span>
              </li>
              <li>
                <a :href="'tel:'+run.donor.phone">
                  <img src="/images/phone.svg">
                  <span>{{ run.donor.phone }}</span>
                </a>
              </li>
            </ul>
          </address>
        </div>
        <div class="content-info">
          <div class="card-info">{{ run.available !== null ? run.available : '-' }}</div>
          <div class="avail-mark">
            <img src="/images/bun.svg">
            <div v-if="$store.getters.isAdmin">
              <v-btn @click="$emit('estimate', runId)">{{ run.expectedAmount || 'No info' }}</v-btn>
            </div>
            <div v-else>
              <span>{{ run.expectedAmount || $t("runcard.none") }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="foot-left" v-if="$store.state.auth.user.role === 'volunteer' || $store.state.auth.user.role === 'leader' || $store.state.auth.user.role === 'group' ">
          <img src="/images/mark.svg" />
          <span>{{ nearestMtrStation }} - {{ $t("runcard.exit")}} {{ run.donor.nearestMtrExit }}</span>
        </div>
        <div class="foot-left card-status" v-else>
          <span v-if="run.claimed">claimed&nbsp;by:&nbsp;
            <a :href="'tel:'+run.volunteerPhone">
              <strong> {{ run.volunteerName }} </strong>
            </a>
          </span>
          <span v-else>Assigned:&nbsp;<strong>-</strong></span>
        </div>
        <div class="foot-left" v-if="$store.state.auth.user.role === 'leader' && $route.name == 'runs'">
          <v-btn @click.prevent='copy'>
            <img src="/images/share.svg" />
          </v-btn>
        </div>
        <div class="foot-right">
          <span class="label">{{ $t("runcard.pick") }}</span>
          <span v-if="run.publicholiday">{{ run.donor.pickupTimes['PH'] }}</span>
          <span v-else>{{ run.donor.pickupTimes[$moment().format('ddd')] }}</span>
        </div>
      </div>
    </template>
  </v-card>
</template>

<script>
export default {
  name: 'RunCard',
  props: {
    run: {
      type: Object,
      required: true
    },
    removable: {
      type: Boolean,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    light: {
      type: Boolean,
      required: false,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    },
    elevation: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      runId: this.run
    };
  },
  methods: {
    capitalize (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    copy () {
      return navigator.clipboard.writeText(this.runLink);
    }
  },
  computed: {
    donorName () {
      if (this.$i18n.locale === 'en') {
        return this.run.donor.nameEn;
      } else {
        return this.run.donor.nameZh;
      }
    },
    encrypt () {
      const conc = this.run.id.toString().concat(this.run.volunteer.id.toString());
      const mult = (+conc * 171).toString();
      return mult.split('').reverse().join('');
    },
    runLink () {
      return 'https://breadline.hk/group/'.concat(this.encrypt);
    },
    nearestMtrStation () {
      if (this.$i18n.locale === 'en') {
        return this.run.donor.nearestMtrStationEn;
      } else {
        return this.run.donor.nearestMtrStationZh;
      }
    },
    wasted () {
      if (this.run.expectedAmount === null && this.run.collectedAmount === 0) {
        return false;
      } else if (this.run.expectedAmount !== '0' && this.run.collectedAmount === 0) {
        return true;
      } else {
        return false;
      }
    },
    donorAddress () {
      if (this.$route.name === 'runs.view') {
        if (this.$i18n.locale === 'en') {
          return this.run.donor.addressFullEn;
        } else {
          return this.run.donor.addressFullZh;
        }
      } else {
        if (this.$i18n.locale === 'en') {
          return this.run.donor.addressDisplayEn;
        } else {
          return this.run.donor.addressDisplayZn;
        }
      }
    }
  }
};
</script>
